function setImage(image: any) {
  const api_url = process.env.NEXT_PUBLIC_BASE_API_URL;
  try {
    if (!image) return "";

    switch (true) {
      case image.startsWith("https://"):
        return image;
      case image.startsWith("images"):
      case image.startsWith("files"):
      case image.startsWith("templates"):
        return `${api_url}/${image}`;
      default:
        return `${api_url}/uploads/${image}`;
    }
  } catch (error) {
    return "";
  }
}

export { setImage };
