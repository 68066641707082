"use client";

import Link from "next/link";
import Prices from "./Prices";
import Image from "next/image";
import toast from "react-hot-toast";
import React, { FC, useEffect, useState } from "react";
import { useRouter } from "next/navigation";
import { Transition } from "@/app/headlessui";
import ModalQuickView from "./ModalQuickView";
import NcImage from "@/shared/NcImage/NcImage";
import { setImage } from "@/utils";

export interface ProductCardProps {
  data?: any;
  // data?: Product;
  className?: string;
}

const ProductCard: FC<ProductCardProps> = ({
  className = "",
  data,
}: ProductCardProps) => {
  const [variantActive, setVariantActive] = useState(0);
  const [showModalQuickView, setShowModalQuickView] = useState(false);
  const router = useRouter();

  const notifyAddTocart = ({ size }: { size?: string }) => {
    toast.custom(
      (t) => (
        <Transition
          as={"div"}
          appear
          show={t.visible}
          className="p-4 max-w-md w-full bg-white dark:bg-slate-800 shadow-lg rounded-2xl pointer-events-auto ring-1 ring-black/5 dark:ring-white/10 text-slate-900 dark:text-slate-200"
          enter="transition-all duration-150"
          enterFrom="opacity-0 translate-x-20"
          enterTo="opacity-100 translate-x-0"
          leave="transition-all duration-150"
          leaveFrom="opacity-100 translate-x-0"
          leaveTo="opacity-0 translate-x-20"
        >
          <p className="block text-base font-semibold leading-none">
            Added to cart!
          </p>
          <div className="border-t border-slate-200 dark:border-slate-700 my-4" />
          {/* {renderProductCartOnNotify({ size })} */}
        </Transition>
      ),
      {
        position: "top-right",
        id: "product-detail",
        duration: 3000,
      }
    );
  };

  // const renderProductCartOnNotify = ({ size }: { size?: string }) => {
  //   return (
  //     <div className="flex ">
  //       <div className="h-24 w-20 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
  //         <Image
  //           width={80}
  //           height={96}
  //           src={image}
  //           alt={name}
  //           className="absolute object-cover object-center"
  //         />
  //       </div>

  //       <div className="ms-4 flex flex-1 flex-col">
  //         <div>
  //           <div className="flex justify-between ">
  //             <div>
  //               <h3 className="text-base font-medium ">{name}</h3>
  //               <p className="mt-1 text-sm text-slate-500 dark:text-slate-400">
  //                 <span>
  //                   {variants ? variants[variantActive].name : `Natural`}
  //                 </span>
  //                 <span className="mx-2 border-s border-slate-200 dark:border-slate-700 h-4"></span>
  //                 <span>{size || "XL"}</span>
  //               </p>
  //             </div>
  //             <Prices price={price} className="mt-0.5" />
  //           </div>
  //         </div>
  //         <div className="flex flex-1 items-end justify-between text-sm">
  //           <p className="text-gray-500 dark:text-slate-400">Qty 1</p>

  //           <div className="flex">
  //             <button
  //               type="button"
  //               className="font-medium text-primary-6000 dark:text-primary-500 "
  //               onClick={(e) => {
  //                 e.preventDefault();
  //                 router.push("/cart");
  //               }}
  //             >
  //               View cart
  //             </button>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  const getBorderClass = (Bgclass = "") => {
    if (Bgclass.includes("red")) {
      return "border-red-500";
    }
    if (Bgclass.includes("violet")) {
      return "border-violet-500";
    }
    if (Bgclass.includes("orange")) {
      return "border-orange-500";
    }
    if (Bgclass.includes("green")) {
      return "border-green-500";
    }
    if (Bgclass.includes("blue")) {
      return "border-blue-500";
    }
    if (Bgclass.includes("sky")) {
      return "border-sky-500";
    }
    if (Bgclass.includes("yellow")) {
      return "border-yellow-500";
    }
    return "border-transparent";
  };

  return (
    <>
      <div
        className={`nc-ProductCard max-w-64 relative flex flex-col pb-2 bg-white rounded-2xl divide-y
          shadow-[-10px_-10px_30px_4px_rgba(0,0,0,0.1),_10px_10px_30px_4px_rgba(255,255,255,0.15)] 
          ${className}`}
      >
        <Link href={"/product-detail"} className="absolute inset-0"></Link>

        <div className="relative h-40 flex-shrink-0 bg-slate-50 dark:bg-slate-300 overflow-hidden z-1  max-h-40 rounded-t-2xl">
          <Link href={`/product/${data?.id}`} className="">
            <NcImage
              fill
              alt="product"
              src={setImage(data?.images[0]?.imageUrl)}
              className="object-none w-full h-full drop-shadow-xl"
              containerClassName=""
              sizes="(max-width: 640px) 60vw, (max-width: 1200px) 40vw, 10vw"
            />
          </Link>
        </div>

        <div className="space-y-4 px-3 pt-5 pb-2.5">
          <div>
            <h2 className="nc-ProductCard__title text-base font-semibold line-clamp-1 transition-colors capitalize">
              {data?.name}
            </h2>
            <p className={`text-sm text-slate-500 dark:text-slate-400 mt-1 line-clamp-2 lowercase`}>
              {data?.description}
            </p>
          </div>

          <div className="flex justify-between items-end ">
            <Prices price={data?.price} />
            <div className="flex items-center mb-0.5">
              <span className="text-sm ms-1 text-slate-500 dark:text-slate-400">
                en stock
              </span>
            </div>
          </div>
        </div>
      </div>

      <ModalQuickView
        show={showModalQuickView}
        onCloseModalQuickView={() => setShowModalQuickView(false)}
      />
    </>
  );
};

export default ProductCard;
