import api from "@/services/api";
const base = "/products";

const Product = {
  index: () => api("GET", `${base}/paginate`, "", `getting products list`),
  update: (payload: any, id: string) =>
    api("PUT", `${base}/${id}`, payload, `updating products`),
  delete: (id: any) => api("DEL", `${base}/${id}`, "", `deleting products`),
  create: (payload: any) =>
    api("POST", `${base}`, payload, `creating products`),
  getProduct: (id: string) =>
    api("GET", `${base}/${id}`, "", `getting single product`),
};

export default Product;
